import { render, staticRenderFns } from "./completed-projects-employer.html?vue&type=template&id=6440cc75&scoped=true&"
import script from "./completed-projects-employer.js?vue&type=script&lang=js&"
export * from "./completed-projects-employer.js?vue&type=script&lang=js&"
import style0 from "./completed-projects-employer.scss?vue&type=style&index=0&id=6440cc75&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6440cc75",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VImg } from 'vuetify/lib/components/VImg';
import { VPagination } from 'vuetify/lib/components/VPagination';
installComponents(component, {VBtn,VDataTable,VImg,VPagination})
